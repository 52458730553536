@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/poppins/Poppins-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

* {
  font-family: "Poppins", "sans-serif";
  font-size: 13px;
}

html {
  background-image: none !important;
  background: #fbfaff !important;
}

#left-panel {
  display: none !important;
}

.menu-on-top #main {
  margin-top: 30px !important;
}

fieldset {
  border-radius: 3px;
}

.jarviswidget h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
div {
  font-family: "Poppins", sans-serif;
}

body {
  background-image: none !important;
  background: #fbfaff;
}

.exp h3 {
  margin-bottom: 5px;
  margin-top: 20px;
  color: black;
}

.exp h3,
.exp ul,
.exp li,
.exp p {
  text-align: left !important;
}

.exp h1 {
  display: inline-block;
  margin-bottom: 15px;
  background: #f7f7f7;
  padding: 7px;
  border: 1px solid #e4e4e4;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 35px;
}

.mt-1 {
  margin-top: 10px;
}

.cf-dynamic-grid .dx-gridbase-container>.dx-datagrid-rowsview.dx-scrollable {
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
}

.cf-dynamic-grid {
  margin: 20px 0 !important;
}

.cf-dynamic-grid .dx-datagrid-headers {
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
}

.cf-dynamic-grid .dx-scrollable-content {
  border-bottom: 1px solid #e6e6e6 !important;
}

label.admin-editing-mode {
  border: 1px solid blue;
  padding: 3px;
  border-style: dashed;
}

.form-group.admin-editing-mode {
  border: 1px solid orange;
  padding: 3px;
  border-style: dashed;
}

a.admin-editing-mode {
  border: 1px solid orange !important;
  padding: 3px;
  border-style: dashed !important;
}

legend.admin-editing-mode {
  display: flex !important;
  align-items: baseline !important;
  border: 1px solid orange !important;
  padding: 3px !important;
  border-style: dashed !important;
}

.editing-mode-delete-icon {
  background: #d91a1a;
}

.editing-mode-edit-icon {
  background: #c79121;
  margin-left: 5px;
}

.cf-dynamic-grid td.dx-datagrid-action,
.cf-dynamic-grid .dx-texteditor-input,
.cf-dynamic-grid td[class*="dx-col"] {
  text-align: left !important;
}

.dynamic-grid-title {
  position: relative;
  color: black;
  top: 25px;
  z-index: 1;
  max-width: 70%;
}

.dynamic-grid-container {
  min-width: 400px;
  margin-top: 30px;
}

.sticky,
.sticky-client {
  background: #fbfaff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.jarviswidget>div {
  border: none !important;
  -webkit-box-shadow: 0 2px 5px 0px #a0a0a04d !important;
  box-shadow: 0 2px 5px 0px #a0a0a04d !important;
}

.jarviswidget>header {
  border: none !important;
  background: #f4f4f4 !important;
  -webkit-box-shadow: 0 1px 5px 0px #b3b3b34d !important;
  box-shadow: 0 1px 5px 0px #b3b3b34d !important;
  color: rgb(71, 71, 71) !important;
}

#search-clients header {
  background: white !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#search-clients .jarviswidget {
  margin: 0;
}

.payment-methods-custom-fields-form {
  position: relative;
  top: -35px;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 97% !important;
  overflow: hidden !important;
}

.text-color-blue {
  color: #5171ce !important;
}

.pText {
  margin-top: 10px !important;
}

.dx-texteditor.dx-editor-outlined {
  border: 1px solid #bbb;
}

.btn:hover {
  filter: brightness(.95) !important;
}

input[type=text],
input[type=number],
input[type=date] {
  border-radius: 1px !important;
  padding: 16px ​10px ​ !important;
}

.m20 {
  margin: 20px 0;
}

.boder-bottom-grey {
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  padding-bottom: 3px;
}

.payment-methods-custom-fields-form fieldset {
  padding: 0;
}

#search-clients div,
#search-clients a {
  border: none;
}

button[disabled],
html input[disabled] {
  cursor: not-allowed !important;
}


#search-clients .jarviswidget-ctrls {
  border: 1px solid #838383 !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  max-height: 31px !important;
}

.jarviswidget .widget-icon i,
.jarviswidget .button-icon {
  color: rgb(71, 71, 71) !important;
}

.ngx-datatable.bootstrap .datatable-header {
  background-color: #e4edf6 !important;
  color: #062d7d !important;
}

.datatable-row-wrapper:nth-child(even) {
  background: #f6f6f7 !important;
}

.well {
  background: white;
  border: none !important;
  box-shadow: 0px 0px 3px 0px #44444430 !important;
  ;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-grid-icon {
  cursor: pointer;
}


/* LEFT SIDEBAR */

#left-panel {
  background: #e9e9e9 !important;
  border: none !important;
}

.menu-item-parent {
  max-width: none !important;
}

.smart-style-1 nav ul ul li>a {
  text-shadow: none;
}

.smart-style-1 nav ul li a span,
.smart-style-1 nav ul li a i {
  color: #696969 !important
}

.smart-style-1 nav ul li:hover a span,
.smart-style-1 nav ul li:hover a i {
  color: #383838 !important
}

.smart-style-1 nav ul * {
  background: #e9e9e9 !important;
  color: black !important;
}

.smart-style-1 nav ul ul li.active a {
  font-weight: bold;
}

.smart-style-1 nav ul li.active a span,
.smart-style-1 nav ul li.active a i {
  color: black !important;
}

.smart-style-1 nav ul li:hover a {
  border-color: transparent !important;
}

.smart-style-1 nav ul li ul * {
  background: #cfcfcf !important;
  color: #6f6f6f !important;
}

.smart-style-1 nav ul li ul *:hover {
  background: #cfcfcf !important;
  color: #505050 !important;
}


.smart-style-1 nav ul li,
.smart-style-1 nav ul li:hover {
  border-right: 1px solid #d6d6d6 !important;
  border-left-color: transparent !important;
}

.smart-style-1 nav a:hover {
  color: rgb(34, 34, 34) !important;
}

.smart-style-1 nav ul li.active>a:before {
  content: "";
}

.smart-style-1 nav .top-menu-invisible.open a,
.smart-style-1 nav .top-menu-invisible.open .collapse-sign {
  color: black !important;
}

.smart-style-1 nav li.active a {
  color: black !important;
}

.smart-style-1 nav ul b {
  font-size: 16px;
}

.smart-style-1 nav ul b .fa.fa-plus-square-o {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

#details-bar {
  position: relative !important;
  top: -14px !important;
  background: white !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px !important;
  padding: 40px 15px !important;
}

.w-100 {
  width: 100%;
}

#admin-options-panel {
  position: relative;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  background: white;
  display: flex;
  padding: 16px;
}


/* NAVBAR */

#header {
  -webkit-box-shadow: 0px 2px 3px 0px #44444430 !important;
  box-shadow: 0px 2px 3px 0px #44444430 !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

#header .menu-icon {
  margin-right: 5px;
  font-size: 11px;
}

.smart-style-1 .custom-logo-width-main {
  margin-left: 15px;
}

.client-dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
}

#country-code-selectbox .dx-texteditor-container {
  max-height: 42px !important;
  border: 1px solid white !important;
}

.dx-button.dx-button-success {
  background-color: #72a272;
  margin: 0 5px 0 15px;
  max-width: 100px;
}

.dx-button.dx-button-success:hover {
  background-color: #5d855d;
}

.header-dropdown-list>li>.dropdown-toggle {
  margin: 0;
}

#header .flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#header .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  height: 100%;
}

.documents-grid-editable td {
  overflow: visible !important;
}

.full-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
}

.dxChrome dx-select-box {
  border: none !important;
}

.dxChrome dx-drop-down-box {
  border: none !important;
}

.modal-header .close span {
  font-size: 1.5em;
}

.txt-color-orange strong,
.txt-color-orangeDark strong {
  font-size: 2em;
}

.portal-popover {
  cursor: help;
  position: relative;
  left: 2px;
}

.Pending-ver {
  color: orange;
  background: #fffdcc;
  padding: 5px 10px;
  border-radius: 2px;
  letter-spacing: 0.07rem;
}

.Success-ver {
  color: #07690e;
  background: #e8ffe1;
  padding: 5px 10px;
  border-radius: 2px;
  letter-spacing: 0.07rem;
}

.custom-badge-green {
  background: green;
}

.col-sm-4.custom-position {
  position: relative;
  bottom: 6px;
}

.Rejected-ver {
  color: #ab0800;
  background: #ffe5e1;
  padding: 5px 10px;
  border-radius: 2px;
  letter-spacing: 0.07rem;
}

dx-select-box .dx-texteditor-input,
dx-drop-down-box .dx-texteditor-input {
  padding: 8.5px 10px !important;
  font-size: 1em !important;
}

.smart-form .input-file .button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  float: none;
  height: auto;
  margin: 0;
  padding: 0 14 px;
  font-size: 13px;
  line-height: 2.5;
}

.doc-ver-badge-header {
  padding: 7px 20px !important;
  border-radius: 2px !important;
}

.shimmer-loading .ngx-shimmer {
  animation: 2s linear infinite forwards shimmerEffect !important;
}

dx-select-box .dx-placeholder,
dx-drop-down-box .dx-placeholder {
  font-size: 1em !important;
  top: -8px !important;
  color: #6b6b6b !important;
}

.dx-toolbar-button .dx-button-has-text .dx-button-content .dx-icon {
  font-size: 1.5em;
}

.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
  opacity: 1 !important;
}

.dx-state-disabled .dx-texteditor-container {
  background: #eceeef !important;
}

.dx-texteditor.dx-editor-outlined.dx-state-disabled,
.dx-texteditor.dx-editor-outlined.dx-state-readonly,
.dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover {
  border: 1px solid #a0a0a0 !important;
}

#header .client-options-dropdown {
  height: 100%;
  min-width: 150px;
  margin-left: 30px;
  padding: 0 30px 0 15px;
  border-left: 1px solid #00000014;
  border-right: 1px solid #00000014;
}

#header .user-username {
  font-size: 1.14em;
  color: rgb(44, 44, 44);
}

#header .user-role {
  font-size: .8em;
  color: rgb(110, 110, 110);
}

#header .client-options-dropdown .dropdown-arrow {
  color: rgb(78, 78, 78);
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-80%, -50%);
}

.dropdown-menu.client>li a {
  display: block;
  padding: 5px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: rgb(92, 92, 92);
  white-space: nowrap;
  background: white !important;
}

.dropdown-menu.client>li a:hover {
  color: rgb(58, 58, 58);
  background: rgb(241, 241, 241) !important;
}

.dropdown-menu.client>li a i {
  margin-right: 5px;
}

.smart-style-1 #logo-group span#activity {
  background: white;
  border-color: white;
  color: #656565;
}

#activity-badge {
  padding: 0 !important;
  width: 15px !important;
  height: 15px !important;
}

#activity {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  position: relative;
}

#notifications-dropdown {
  top: 42px;
  left: -163px;
}

#notifications-dropdown .ajax-notifications {
  height: 345px;
}

.valid-password-condition {
  color: rgb(98, 221, 98) !important;
}

#activity b.badge {
  right: -5px;
  top: -10px;
}

#activity .not-bell {
  cursor: pointer;
  font-size: 18px;
}


/* GENERAL */

.custom-text-color-black {
  color: black !important;
}

.dx-item-content.dx-list-item-content {
  font-size: 1em !important;
}

.custom-text-color-grey {
  color: #5f5f5f !important;
  font-size: .85em;
}

ul.list-style-none {
  margin: 0 !important;
  padding: 5px !important;
  overflow: auto;
  white-space: nowrap;
}

ul.list-style-none::-webkit-scrollbar-thumb {
  background: transparent;
}

ul.list-style-none:hover::-webkit-scrollbar-thumb {
  background: #888;
}

.sticky .items-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#admin-options-panel .items-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.percentage-icon {
  position: relative;
  font-size: 1.2em;
  right: 33px;
  background: #e5e5e5;
  color: #636161;
  padding: 4.5px 10px;
}

.field-description-popover {
  cursor: help !important;
  margin-right: 3px !important;
}

.sticky .items-wrapper #saSparklineContainer {
  margin-left: auto;
}

ul.list-style-none .wrapper {
  text-align: end;
}

ul.list-style-none li {
  list-style: none;
  border-right: 1px solid grey;
  display: inline-block;
}

ul.list-style-none li:last-child {
  border-right-color: transparent;
}

ul.list-style-none li:first-child {
  border-left: 1px solid grey;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.menu-on-top .menu-item-parent {
  font-size: 14px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-flex.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.d-flex.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.d-flex.space-around {
  justify-content: space-around;
}

.one-line-limit {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.d-flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.d-flex.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.d-flex.center.between {
  align-items: center;
  justify-content: space-between;
}

.d-flex.between {
  justify-content: space-between;
}

.client-quick-actions {
  padding: 0;
  background: #e9ebfa;
  margin-bottom: 0;
}

.same-width {
  width: 220px;
  padding: 0 10px;
}

.same-width .title {
  font-size: 14px;
}

.jarviswidget>header>h2 {
  font-size: 1.03em;
  ;
}

.btn i {
  margin-right: 5px;
}

.same-width:last-child {
  border-right-color: transparent;
}

.client-quick-actions .action-img {
  width: 30px;
  margin-right: 10px;
}

.text-container {
  margin-right: auto;
  text-align: left;
}

.client-quick-actions .count {
  color: black;
  font-size: 27px;
  font-weight: bold;
}

.same-width h6 {
  margin: 0 0 1px 0;
  font-weight: 500;
  padding: 0;
  font-size: 15px;
}

.font-weight-bold {
  font-weight: bold;
}

.small-text-md h4 {
  font-weight: 500 !important;
}

.small-text-md .page-title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.top-nav {
  height: auto;
  padding: 20px 0;
}

.form-control.ng-touched.ng-invalid {
  border-color: #b94a48 !important;
}

.multiselect-dropdown .disabled {
  color: rgb(105, 105, 105) !important;
}

.page-title {
  display: inline-block !important;
}

.smart-form.custom {
  position: relative;
  top: -20px;
}

.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 100%;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.show-on-mobile {
  display: none;
}

.top-menu-ul {
  display: flex;
  list-style: none;
  align-items: center;
  height: 100%;
}

.warning-label-link {
  color: #094fbc;
  text-decoration: underline;
}

.top-menu-ul li a {
  padding: 19px 8px;
  margin-right: 10px;
  color: rgb(105 105 105);
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}

.top-menu-ul li:not(.active) a:hover {
  text-shadow: 0 0 .01px black;
}

.top-menu-ul li.active a {
  border-bottom-color: #7d7d7d;
}

.phase-description-wrapper {
  position: relative;
  bottom: 5px;
  border-left: none !important;
}

.phase-description {
  font-size: 1.3em;
  cursor: help;
  color: #5b5b5b;
  border-radius: 50%;
  padding-left: 5px;
}

input:disabled {
  background: #eceeef !important;
}

.modal-header {
  padding: 15px 33px !important;
}

.btn.btn-secondary {
  border: 1px solid #cbcbcb !important;
}

.modal-body {
  padding: 20px !important;
  line-height: 1.6em;
}

.smart-style-1 #hide-menu i {
  color: grey !important;
}

.holder {
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.container-fluid.no-padding {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
}

.container-fluid.no-padding .row {
  height: 100%;
}

.container-fluid.no-padding .row .col {
  height: 100%;
}

.container-fluid.no-padding .row .col form header,
.container-fluid.no-padding .row .col form footer {
  background: white !important;
  border: none !important;
}

.sign-in-form,
.sign-up-form {
  position: absolute;
  left: 50%;
  width: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sign-in-form #sign-in-btn,
.sign-up-form #sign-up-btn {
  padding: 6px 22px !important;
  margin: 0 !important;
  width: 100% !important;
  height: auto !important;
}

.sign-in-form .note.fp,
.sign-up-form .note.fp {
  float: right;
  margin-top: 15px;
}

.sign-in-form input:not([type="hidden"]),
.sign-up-form input:not([type="hidden"]) {
  padding: 20px 5px !important;
}

.sign-in-form .icon-append,
.sign-in-form .icon-prepend,
.sign-up-form .icon-append,
.sign-up-form .icon-prepend {
  top: 0px !important;
  height: 100% !important;
  width: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
}

.loading-data-placeholder .shimmer-table {
  height: 650px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

@media only screen and (max-width: 1500px) {
  .small-text-md h4 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 800px) {
  .top-menu-ul {
    display: none;
  }

  .holder {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sections-holder {
    width: 97% !important;
    height: auto !important;
  }

  .sections-wrapper {
    left: 0 !important;
    width: 97% !important;
    top: 30px !important;
    padding: 10px !important;
  }

  .page-title {
    overflow: hidden !important;
    -o-text-overflow: ellipsis !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .page-title {
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: inline-block;
  }

  .steps.show-on-mobile {
    float: left;
    position: absolute;
    left: 5px;
  }

  .phase-description {
    display: none;
  }

  #header .user-username {
    font-size: 1em;
    overflow: hidden;
    white-space: pre;
    width: 80px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .custom-filter-input {
    min-width: 50% !important;
  }

  .holder {
    height: auto;
  }

  .unsaved {
    position: absolute;
    right: 0;
    top: 60%;
    transform: translate(-2%, 100%);
  }

}

@media only screen and (max-width: 479px) and (min-width: 320px) {
  #hide-menu>:first-child>a {
    width: auto !important;
  }
}